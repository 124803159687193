import AppleStoreIcon from '../assets/icons/apple-store.png';
import GooglePlayIcon from '../assets/icons/google-play.png';

const googlePlayURL =
  'https://play.google.com/store/apps/details?id=com.securelogicgroup.trustgrid.communityapp';
const appleStoreURL =
  'https://apps.apple.com/us/app/trustgrid-verify/id1506754463';

/* eslint-disable no-unused-vars */
export const enum OSType {
  Android = 'Android',
  iOS = 'iOS',
  Other = 'Other',
}
export const getOSType = () => {
  const ua = navigator.userAgent;

  if (/android/i.test(ua)) {
    return OSType.Android;
  }

  if (/iPad|iPhone|iPod/.test(ua)) {
    return OSType.iOS;
  }

  return OSType.Other;
};

export const osTypeToIcon = (osType: OSType) => {
  switch (osType) {
    case OSType.Android:
      return GooglePlayIcon;
    case OSType.iOS:
      return AppleStoreIcon;
    default:
      return undefined;
  }
};

export const osTypeToStoreURL = (osType: OSType) => {
  switch (osType) {
    case OSType.Android:
      return googlePlayURL;
    case OSType.iOS:
      return appleStoreURL;
    default:
      return undefined;
  }
};

export type PlatformConfig = {
  url?: string;
  icon?: string;
};

export const getPlatformConfig = (): PlatformConfig => {
  const osType = getOSType();
  const url = osTypeToStoreURL(osType);
  const icon = osTypeToIcon(osType);
  return { url, icon };
};
