import React, { useEffect, useState } from "react";
import axios from "axios";
import WaitForConsent from "./containers/WaitForConsent";
import Rejected from "./components/rejected/Rejected";
import PageNotFound from "./containers/PageNotFound";
import IdentityDetail from "./components/identity/IdentityDetail";

import {
  VerifiedCredential,
  VerificationResponse,
  ConsentState,
} from "./models/models";

function getComponent(
  status: ConsentState,
  credentials?: VerifiedCredential[],
  error?: string
) {
  switch (status) {
    case ConsentState.Granted:
      return <IdentityDetail credentials={credentials} />;

    case ConsentState.Waiting:
    case ConsentState.Pending:
      return <WaitForConsent />;
    case ConsentState.Rejected:
      return <Rejected />;
    case ConsentState.Invalid:
    default:
      return <PageNotFound error={error} />;
  }
}

function App() {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const vid = params.get("vid");
  const uid = params.get("uid");
  const url = params.get("url");

  const [status, setStatus] = useState(ConsentState.Waiting);
  const [error, setError] = useState<string>();

  const [verifiedCredentials, setVerifiedCredentials] =
    useState<VerifiedCredential[]>();

  useEffect(() => {
    let intervalId: ReturnType<typeof setTimeout>;

    if (vid && uid && url) {
      intervalId = setInterval(() => {
        axios
          .get<VerificationResponse>(
            `api/v1/anonymousVerification?vid=${vid}&uid=${uid}&url=${url}`
          )
          .then((response) => {
            setStatus(response.data.status as ConsentState);
            setVerifiedCredentials(response.data.verifiedCredentials);
          })
          .catch((err) => {
            console.log(err);
            const errMessage = err?.response?.data?.error || err.message;
            setError(errMessage);
            setStatus(ConsentState.Invalid);
          });
      }, 5000);
      if (
        status === ConsentState.Granted ||
        status === ConsentState.Rejected ||
        status === ConsentState.Invalid
      ) {
        clearInterval(intervalId);
      }
    } else {
      setError("missing parameters");
      setStatus(ConsentState.Invalid);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [vid, uid, url, status]);

  return <>{getComponent(status, verifiedCredentials, error)}</>;
}

export default App;
