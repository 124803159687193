/* eslint-disable no-unused-vars */
// keys to be in lower case and without whitespace
// all keys received from backend will be normalized (i.e converted
// into lower case and without whitespace)
// verified Credentials lookup will happen with normalized keys

export enum REJECT_FIELD_IDS {
  exclaimationMark = 'exclaimationmark',
  yourRequest = 'yourrequest',
  hasBeenRejected = 'hasbeenrejected',
}

export type REJECT_FIELD_DEF_TYPE = {
  [key: string]: {
    label?: string;
    valuePrefix?: string;
    valueSuffix?: string;
  };
};

export const REJECT_FIELD_DEFS: REJECT_FIELD_DEF_TYPE = {
  [REJECT_FIELD_IDS.yourRequest]: {
    label: 'Your request',
  },
  [REJECT_FIELD_IDS.hasBeenRejected]: {
    label: 'has been',
    valueSuffix: 'rejected!',
  },
};

export const bodyDisplayOrder = [
  REJECT_FIELD_IDS.exclaimationMark,
  REJECT_FIELD_IDS.yourRequest,
  REJECT_FIELD_IDS.hasBeenRejected,
];
