import React, { ReactNode } from 'react';
import { Typography, Grid, Box, SxProps, Container } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Divider from '../../components/Divider';
import Header from '../../components/common/Header';
import { bodyDisplayOrder, REJECT_FIELD_IDS, REJECT_FIELD_DEFS } from './types';

const styles = {
  container: {
    minHeight: '100%',
    padding: 0,
  },
  gridContainer: {
    backgroundColor: '#FFFFFF',
    color: '#000',
    pl: 5,
    pr: 4,
  },
  hasBeenRejectedContainer: {
    display: 'flex',
    flexDirection: 'row',
    mb: 40,
  },
  [`${REJECT_FIELD_IDS.exclaimationMark}`]: {
    mt: 20,
    mb: 2,
    color: '#EDDD3E',
    fontSize: 30,
  },
  [`${REJECT_FIELD_IDS.yourRequest}`]: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 27,
  },
  [`${REJECT_FIELD_IDS.hasBeenRejected}`]: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 27,
  },
  [`${REJECT_FIELD_IDS.hasBeenRejected}Bold`]: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 27,
  },
};

const Rejected: React.FC = function () {
  const getComponent = (fieldID: REJECT_FIELD_IDS): ReactNode => {
    let Component;

    const label = REJECT_FIELD_DEFS[fieldID]?.label;
    switch (fieldID) {
      case REJECT_FIELD_IDS.exclaimationMark: {
        Component = <ErrorIcon sx={styles[`${fieldID}`] as SxProps} />;
        break;
      }
      case REJECT_FIELD_IDS.yourRequest: {
        Component = (
          <Box>
            <Typography sx={styles[`${fieldID}`] as SxProps}>
              {label}
            </Typography>
          </Box>
        );
        break;
      }
      case REJECT_FIELD_IDS.hasBeenRejected: {
        Component = (
          <Box sx={styles.hasBeenRejectedContainer as SxProps}>
            <Typography sx={styles[`${fieldID}`] as SxProps}>
              {label}&nbsp;
            </Typography>
            <Typography sx={styles[`${fieldID}Bold`] as SxProps}>
              {REJECT_FIELD_DEFS[fieldID]?.valueSuffix}
            </Typography>
          </Box>
        );
        break;
      }

      default:
        Component = (
          <Box>
            <Typography>{fieldID}</Typography>
          </Box>
        );
        break;
    }
    return Component;
  };

  const renderBody = () =>
    bodyDisplayOrder?.map(fieldID => (
      <Grid item xs={12} key={fieldID}>
        {getComponent(fieldID)}
      </Grid>
    ));

  return (
    <Container maxWidth="xs" sx={styles.container}>
      <Divider />
      <Header />
      <Divider />
      <Grid container sx={styles.gridContainer} spacing={0}>
        {renderBody()}
      </Grid>
    </Container>
  );
};

export default Rejected;
