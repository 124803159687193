import React from "react";
import DrivingLicenseApproved from "./dl/usa/DriverLicenseView";
import EducationCertificate from "./education/usa/certificate/EducationCertificate";
import EducationCertTranscript from "./education/usa/EducationCertTranscript";
import CovidCertificate from "./covid/brunei/CovidCertificate";
import GenericIdentity from "./generic/GenericIdentity";
import ESGReport from "./esg/ESGReport";
import ESGCertificate from "./esg/ESGCertificate";
import Above18 from "./ageproof/Above18";
import { VerifiedCredential } from "../../models/models";
import { findIDType, IDType, normalizeAsMap } from "./util";

const DetailMap = {
  [IDType.DL]: DrivingLicenseApproved,
  [IDType.EducationCertTranscript]: EducationCertTranscript,
  [IDType.EducationCertificate]: EducationCertificate,
  [IDType.Generic]: GenericIdentity,
  [IDType.Above18]: Above18,
  [IDType.CovidCertificate]: CovidCertificate,
  [IDType.ESGReport]: ESGReport,
  [IDType.ESGCertificate]: ESGCertificate,
};

type Props = {
  credentials?: VerifiedCredential[];
};

const IdentityDetail: React.FC<Props> = function ({ credentials, ...rest }) {
  const idType = findIDType(credentials);
  const Component = DetailMap[idType];
  const credentialsMap = normalizeAsMap(credentials);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component credentialsAsMap={credentialsMap} {...rest} />;
};

IdentityDetail.defaultProps = {
  credentials: [],
};

export default IdentityDetail;
