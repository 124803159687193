/* eslint-disable no-unused-vars */
// keys to be in lower case and without whitespace
// all keys received from backend will be normalized (i.e converted
// into lower case and without whitespace)
// verified Credentials lookup will happen with normalized keys

export enum TS_FIELD_IDS {
  institueName = 'institutename',
  institueAddress = 'instituteaddress',
  candidateName = 'candidatename',
  id = 'id',
  dateOfBirth = 'dateofbirth',
  courseWork = 'coursework',
  terms = 'terms',
  endOfTranscript = 'endoftranscript',
  institueLogo = 'institutelogo',
  signature1 = 'signature1',
  privacy = 'privacy',
  conferred = 'conferred',
  cumulative = 'cumulative',
}

export type TS_FIELD_DEF_TYPE = {
  [key: string]: {
    label?: string;
    valuePrefix?: string;
    valueSuffix?: string;
  };
};

export const TS_FIELD_DEFS: TS_FIELD_DEF_TYPE = {
  [TS_FIELD_IDS.candidateName]: {
    label: 'Name',
  },
  [TS_FIELD_IDS.id]: {
    label: 'Student Number',
  },
  [TS_FIELD_IDS.dateOfBirth]: {
    label: 'Date Of Birth',
  },
  [TS_FIELD_IDS.courseWork]: {
    label: 'Coursework undertaken at the university',
  },
  [TS_FIELD_IDS.endOfTranscript]: {
    label: '***  END OF TRANSCRIPT  ***',
  },
  [TS_FIELD_IDS.privacy]: {
    label:
      // eslint-disable-next-line max-len
      'In accordance with the Family education rights and privacy act of 1974, information from this transcript may not be released to a third party without written consent of the student.',
  },
  [TS_FIELD_IDS.conferred]: {
    label: 'Conferred: ',
  },
};

export const candidateDisplayOrder = [
  TS_FIELD_IDS.institueName,
  TS_FIELD_IDS.institueAddress,
  TS_FIELD_IDS.candidateName,
  TS_FIELD_IDS.id,
  TS_FIELD_IDS.dateOfBirth,
];

export const termsDisplayOrder = [
  TS_FIELD_IDS.courseWork,
  TS_FIELD_IDS.terms,
  TS_FIELD_IDS.endOfTranscript,
];

export const signatureDisplayOrder = [
  TS_FIELD_IDS.institueLogo,
  TS_FIELD_IDS.signature1,
];

export const privacyDisplayOrder = [
  TS_FIELD_IDS.privacy,
  TS_FIELD_IDS.conferred,
];
