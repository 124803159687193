import React from 'react';

const styles = {
  container: {
    backgroundColor: '#BFBFBF',
    height: '0.5px',
  },
};

const Divider = function () {
  return <div style={styles.container} />;
};

export default Divider;
