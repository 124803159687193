/* eslint-disable no-unused-vars */
// keys to be in lower case and without whitespace
// all keys received from backend will be normalized (i.e converted
// into lower case and without whitespace)
// verified Credentials lookup will happen with normalized keys

export enum UC_FIELD_IDS {
  candidateName = 'candidatename',
  institueName = 'institutename',
  institueLogo = 'institutelogo',
  educationTitle = 'educationtitle',
  dateOfIssue = 'dateofissue',
  issuedBy = 'issuedby',
  signature1 = 'signature1',
  signature1Title = 'signature1Title',
  signature2 = 'signature2',
  signature2Title = 'signature2Title',
  signature3 = 'signature3',
  signature3Title = 'signature3Title',
  signature4 = 'signature4',
  signature4Title = 'signature4Title',
  content = 'content', // for UI use
}

export type UC_FIELD_DEF_TYPE = {
  [key: string]: {
    label?: string;
    valuePrefix?: string;
    valueSuffix?: string;
  };
};

export const UC_FIELD_DEFS: UC_FIELD_DEF_TYPE = {
  [UC_FIELD_IDS.candidateName]: {
    label: 'has conferred on',
  },
  [UC_FIELD_IDS.educationTitle]: {
    label: 'the degree of',
  },
  [UC_FIELD_IDS.dateOfIssue]: {
    label: 'award on this',
  },
  [UC_FIELD_IDS.content]: {
    label: `and all the rights and privileges thereto appertaining.
              In Witness Thereof, this diploma duly signed has been issued 
              and the seal of the University affixed.`,
  },
  [UC_FIELD_IDS.issuedBy]: {
    valuePrefix: 'Issued by the',
    valueSuffix: 'upon Recommendation of the Faculty',
  },
};

export const bodyDisplayOrder = [
  UC_FIELD_IDS.institueLogo,
  UC_FIELD_IDS.institueName,
  UC_FIELD_IDS.candidateName,
  UC_FIELD_IDS.educationTitle,
  UC_FIELD_IDS.content,
  UC_FIELD_IDS.issuedBy,
  UC_FIELD_IDS.dateOfIssue,
];

export const signatureDisplayOrder = [
  UC_FIELD_IDS.signature1,
  UC_FIELD_IDS.signature2,
  UC_FIELD_IDS.signature3,
  UC_FIELD_IDS.signature4,
];
