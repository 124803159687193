import React from 'react';
import Box from '@mui/material/Box';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { poppinsRegular } from '../../themes/themes';

export default function RequestApprovedBanner() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        px: 4,
        height: 40,
        backgroundColor: '#96fa89',
      }}
    >
      <CheckCircleRoundedIcon sx={{ width: 18, height: 18, mr: 1 }} />
      <ThemeProvider theme={poppinsRegular}>
        <Typography
          variant="body2"
          component="div"
          textAlign="left"
          sx={{ fontWeight: 'light' }}
        >
          Verified by issued authority
        </Typography>
      </ThemeProvider>
    </Box>
  );
}
