/* eslint-disable no-unused-vars */

import { VerifiedCredential } from "../../models/models";

type Attributes = {
  [key: string]: string[];
};

// define all attributes with lowercase and without whitespace
export const ATTRIBUTES: Attributes = {
  NAME: ["name"],
  FIRST_NAME: ["firstname", "givenname", "givenname(s)"],
  LAST_NAME: ["lastname", "surname"],
  PROFILE_PIC: ["profilepic"],
  LICENCE_NUMBER: ["licencenumber", "licensenumber", "licenceno", "licenseno"],
  LICENCE_TYPE: ["license", "licence"],
  INSTITUTE_NAME: ["institutename"],
  EDUCATION_CERTIFICATE_TYPE: ["educationcertificate"],
  ABOVE_18: ["above18"],
  TERMS: ["terms"],
  EDUCATION_CERT_TRANSCRIPT_TYPE: ["educationcerttranscript"],
  COVID_CERTIFICATE: ["detailedcovidcertificate", "covidcertficate"],
  COVIC_CERTIFICATE_TYPE: ["covidcertificate"],
  ESG_CERTIFICATE_FLAG: ["esgdigitalcertificateflag"],
  ESG_REPORT_FLAG: ["esgreportflag"],
};

const makeName = (name: string, firstName: string, lastName: string) => {
  let displayName = name;
  if (!displayName && firstName && lastName) {
    displayName = `${firstName} ${lastName}`;
  }
  if (!displayName) {
    displayName = firstName || lastName;
  }

  return displayName;
};

export const getAttributeValue = (
  attributes: string[],
  source: CredentialMap | undefined
): string => {
  const foundAttribute = attributes?.find((attribute) => !!source?.[attribute]);
  return foundAttribute ? source?.[foundAttribute]?.value?.value : undefined;
};

export const makeDisplayName = (source: CredentialMap | undefined): string =>
  makeName(
    getAttributeValue(ATTRIBUTES.NAME, source),
    getAttributeValue(ATTRIBUTES.FIRST_NAME, source),
    getAttributeValue(ATTRIBUTES.LAST_NAME, source)
  );

export const normalizeText = (text: string) =>
  typeof text === "string" ? text.replace(/\s+/g, "").toLowerCase() : text;

// key will be in lower case without any whitespace
export type CredentialMap = {
  [key: string]: VerifiedCredential;
};

export function credentialsAsMapToAttributes<T>(
  credentialsAsMap?: CredentialMap
): T {
  const attrs: Record<string, string> = {};

  if (credentialsAsMap === undefined) {
    return attrs as T;
  }

  Object.keys(credentialsAsMap).forEach((k) => {
    const { name } = credentialsAsMap[k];
    const { value } = credentialsAsMap[k].value;
    attrs[name] = value;
  });

  return attrs as T;
}

export const normalizeAsMap = (
  credentials: VerifiedCredential[] | undefined
): CredentialMap | undefined =>
  credentials?.reduce(
    (acc, credential) => ({
      ...acc,
      [normalizeText(credential?.name)]: credential,
    }),
    {}
  );

export enum IDType {
  DL = "DL",
  EducationCertificate = "EducationCertificate",
  EducationCertTranscript = "EducationCertTranscript",
  Above18 = "Above18",
  Generic = "Generic",
  CovidCertificate = "CovidCertificate",
  ESGCertificate = "ESGCertificate",
  ESGReport = "ESGReport",
}

export type IDTypeDef = {
  [key: string]: {
    type: IDType;
    keyAttributes: string[];
    credentialTypes: string[];
  };
};

export const IDTypes: IDTypeDef = {
  [IDType.Above18]: {
    type: IDType.Above18,
    keyAttributes: ATTRIBUTES.ABOVE_18,
    credentialTypes: [],
  },
  [IDType.DL]: {
    type: IDType.DL,
    keyAttributes: ATTRIBUTES.LICENCE_NUMBER,
    credentialTypes: ATTRIBUTES.LICENCE_TYPE,
  },
  [IDType.EducationCertTranscript]: {
    type: IDType.EducationCertTranscript,
    keyAttributes: ATTRIBUTES.TERMS,
    credentialTypes: ATTRIBUTES.EDUCATION_CERT_TRANSCRIPT_TYPE,
  },
  [IDType.EducationCertificate]: {
    type: IDType.EducationCertificate,
    keyAttributes: ATTRIBUTES.INSTITUTE_NAME,
    credentialTypes: ATTRIBUTES.EDUCATION_CERTIFICATE_TYPE,
  },
  [IDType.CovidCertificate]: {
    type: IDType.CovidCertificate,
    keyAttributes: ATTRIBUTES.COVID_CERTIFICATE,
    credentialTypes: ATTRIBUTES.COVIC_CERTIFICATE_TYPE,
  },
  [IDType.ESGCertificate]: {
    type: IDType.ESGCertificate,
    keyAttributes: ATTRIBUTES.ESG_CERTIFICATE_FLAG,
    credentialTypes: [],
  },
  [IDType.ESGReport]: {
    type: IDType.ESGReport,
    keyAttributes: ATTRIBUTES.ESG_REPORT_FLAG,
    credentialTypes: [],
  },
};

export const findIDType = (
  verifiedCredentials: VerifiedCredential[] | undefined
): IDType => {
  let idType;

  // eslint-disable-next-line no-restricted-syntax
  for (const { type, keyAttributes, credentialTypes } of Object.values(
    IDTypes
  )) {
    if (
      verifiedCredentials?.find(
        (credential: VerifiedCredential) =>
          keyAttributes?.indexOf(normalizeText(credential.name)) >= 0
      )
    ) {
      idType = type;
      break;
    }

    // check if type attribute exists in verified credentials. if exists, compare the value
    if (!idType) {
      const credentialTypeAttribute = verifiedCredentials?.find(
        (credential: VerifiedCredential) =>
          normalizeText(credential?.name) === "type"
      );

      if (
        credentialTypes?.indexOf(
          normalizeText(credentialTypeAttribute?.value?.value)
        ) >= 0
      ) {
        idType = type;
        break;
      }
    }
  }

  if (!idType) {
    idType = IDType.Generic;
  }

  return idType;
};
