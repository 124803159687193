import { createTheme } from '@mui/material/styles';

export const poppinsRegular = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#4d73ef',
    },
  },
  typography: {
    fontFamily: ['Poppins Regular', 'sans-serif'].join(','),
  },
});

export const poppinsMedium = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#4d73ef',
    },
  },
  typography: {
    fontFamily: ['Poppins Medium', 'sans-serif'].join(','),
  },
});

export const poppinsSemiBold = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#4d73ef',
    },
  },
  typography: {
    fontFamily: ['Poppins SemiBold', 'sans-serif'].join(','),
  },
});

export const poppinsBold = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#4d73ef',
    },
  },
  typography: {
    fontFamily: ['Poppins Bold', 'sans-serif'].join(','),
  },
});

export const roboto = createTheme({
  typography: {
    fontFamily: 'Roboto',
  },
});
