/* eslint-disable no-unused-vars */
// keys to be in lower case and without whitespace
// all keys received from backend will be normalized (i.e converted
// into lower case and without whitespace)
// verified Credentials lookup will happen with normalized keys

export enum FIELD_IDS {
  name = 'name',
  organizationName = 'organizationname',
  organizationLogo = 'organizationlogo',
  backgroundImage = 'backgroundimage',
  dateOfBirth = 'dateofbirth',
  uniqueCertificateIdentifier = 'uniquecertificateidentifier',
  vaccineDose1 = 'vaccinedose1',
  vaccineDose2 = 'vaccinedose2',
  vaccineBooster = 'vaccinebooster',
  vaccineName = 'vaccinename',
  vaccineProductName = 'vaccineproductname',
  vaccineManufacturer = 'vaccinemanufacturer',
  numberInSeriesOfVaccinations = 'numberinseriesofvaccinations',
  dateOfVaccination = 'dateofvaccination',
  countryOfVaccination = 'countryofvaccination',
  certificateIssuer = 'certificateissuer',
  healthCode = 'healthcode',
  healthCodeTitle = 'healthcodetitle',
  healthCodeDescription = 'healthcodedescription',
  healthCodeEffectiveTime = 'healthcodeeffectivetime',
  qrCode = 'qrcode',
  holographicBackgroundImage = 'holographicbackgroundimage',
}

export enum HEALTH_CODES {
  green = 'green',
  red = 'red',
  yellow = 'yellow',
  blue = 'blue',
  purple = 'purple',
}

export type FIELD_DEF_TYPE = {
  [key: string]: {
    label?: string;
  };
};

export const FIELD_DEFS: FIELD_DEF_TYPE = {
  [FIELD_IDS.organizationLogo]: {
    label: 'Logo',
  },
  [FIELD_IDS.organizationName]: {
    label: 'Digital COVID Certificate',
  },
  [FIELD_IDS.name]: {
    label: 'Name',
  },
  [FIELD_IDS.dateOfBirth]: {
    label: 'Date of birth',
  },
  [FIELD_IDS.uniqueCertificateIdentifier]: {
    label: 'Unique Certificate Identifier',
  },
  [FIELD_IDS.vaccineDose1]: {
    label: '1st Dose',
  },
  [FIELD_IDS.vaccineDose2]: {
    label: '2nd Dose',
  },
  [FIELD_IDS.vaccineBooster]: {
    label: 'Booster',
  },
  [FIELD_IDS.vaccineName]: {
    label: 'Vaccine',
  },
  [FIELD_IDS.vaccineProductName]: {
    label: 'Vaccine medical product',
  },
  [FIELD_IDS.vaccineManufacturer]: {
    label: 'Vaccine marketing authorisation holder or manufacturer ',
  },
  [FIELD_IDS.numberInSeriesOfVaccinations]: {
    label:
      'Number in a series of vaccinations/doses and the overall number of doses in the series',
  },
  [FIELD_IDS.dateOfVaccination]: {
    label: 'Date of vaccination',
  },
  [FIELD_IDS.countryOfVaccination]: {
    label: 'Country of vaccination',
  },
  [FIELD_IDS.certificateIssuer]: {
    label: 'Certificate issuer',
  },
  [FIELD_IDS.healthCodeEffectiveTime]: {
    label: 'Effective Time',
  },
  [FIELD_IDS.qrCode]: {
    label: 'QR Code',
  },
};

export const organizationDisplayOrder = [
  FIELD_IDS.organizationLogo,
  FIELD_IDS.organizationName,
];

export const patientDisplayOrder = [
  FIELD_IDS.name,
  FIELD_IDS.dateOfBirth,
  FIELD_IDS.uniqueCertificateIdentifier,
];

export const vaccineDosesDisplayOrder = [
  FIELD_IDS.vaccineDose1,
  FIELD_IDS.vaccineDose2,
  FIELD_IDS.vaccineBooster,
];

export const vaccineDetailsDisplayOrder = [
  FIELD_IDS.vaccineName,
  FIELD_IDS.vaccineProductName,
  FIELD_IDS.vaccineManufacturer,
  FIELD_IDS.numberInSeriesOfVaccinations,
  FIELD_IDS.dateOfVaccination,
  FIELD_IDS.countryOfVaccination,
  FIELD_IDS.certificateIssuer,
];

export const healthCodeDisplayOrder = [
  FIELD_IDS.healthCodeTitle,
  FIELD_IDS.healthCodeDescription,
  FIELD_IDS.healthCodeEffectiveTime,
];
