import React, { ReactNode } from 'react';
import {
  Typography,
  Grid,
  Box,
  Avatar,
  SxProps,
  Container,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import dateFormat from 'dateformat';
import { CredentialMap } from '../../util';
import { VerifiedCredential } from '../../../../models/models';
import Header from '../../../common/Header';
import RequestApprovedBanner from '../../../common/RequestApprovedBanner';

import {
  avatarDisplayOrder,
  profileDisplayOrder,
  credentialDisplayOrder,
  DL_FIELD_IDS,
  DL_FIELD_DEFS,
} from './types';

const styles = {
  container: {
    minHeight: '100%',
    padding: 0,
  },
  gridContainer: {
    backgroundColor: '#FFFFFF',
    color: '#000',
    pl: 4,
    pr: 4,
    backgroundImage: `url(./assets/drive-licence-background.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 120,
    backgroundPositionX: 'left',
    backgroundSize: '100% auto',
  },
  fieldLabelStyle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'left',
    color: 'grey.600',
  },
  fieldValueStyle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 18,
    textAlign: 'left',
  },
  credentialElement: {
    my: 1,
  },
  credentialContainer: {
    my: 1,
  },
  [`${DL_FIELD_IDS.profilePic}Container`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 5,
    px: 4,
  },
  [`${DL_FIELD_IDS.profilePic}`]: {
    width: 200,
    height: 247,
  },
  [`${DL_FIELD_IDS.signature}Container`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    px: 4,
    width: 90,
    height: 'auto',
  },
  [`${DL_FIELD_IDS.signature}`]: {
    width: 90,
    height: 'auto',
    zIndex: 1,
    mt: -3,
    mr: -20,
  },
  [`${DL_FIELD_IDS.cardNumber}`]: {
    my: 1,
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 16,
  },
  [`${DL_FIELD_IDS.givenName}`]: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 32,
  },
  [`${DL_FIELD_IDS.surName}`]: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 32,
    mb: 1,
  },
  [`${DL_FIELD_IDS.organDonor}Container`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    my: 2,
    mx: -1,
  },
  [`${DL_FIELD_IDS.organDonor}`]: {
    width: 18,
    height: 18,
    mr: 1,
  },
};

const getComponent = (
  fieldID: DL_FIELD_IDS,
  credential: VerifiedCredential | undefined
): ReactNode => {
  let Component;
  const value = credential?.value?.value;
  const label = DL_FIELD_DEFS[fieldID]?.label;

  // eslint-disable-next-line default-case
  switch (fieldID) {
    case DL_FIELD_IDS.profilePic: {
      Component = (
        <Box sx={styles[`${fieldID}Container`] as SxProps}>
          <Avatar
            src={value !== undefined ? value : ''}
            alt="Profile Pic"
            variant="square"
            sx={styles[fieldID] as SxProps}
          />
        </Box>
      );
      break;
    }
    case DL_FIELD_IDS.signature: {
      Component =
        value !== undefined ? (
          <Box sx={styles[`${fieldID}Container`] as SxProps}>
            <Avatar
              src={value}
              alt="Signature"
              variant="square"
              sx={styles[fieldID] as SxProps}
            />
          </Box>
        ) : (
          <Box sx={styles[`${fieldID}Container`] as SxProps} />
        );
      break;
    }
    case DL_FIELD_IDS.cardNumber:
    case DL_FIELD_IDS.surName:
    case DL_FIELD_IDS.givenName: {
      Component = (
        <Typography
          component="div"
          textAlign="left"
          sx={styles[fieldID] as SxProps}
        >
          {value}
        </Typography>
      );
      break;
    }

    case DL_FIELD_IDS.address:
    case DL_FIELD_IDS.class:
    case DL_FIELD_IDS.sex:
    case DL_FIELD_IDS.endorsement:
    case DL_FIELD_IDS.height:
    case DL_FIELD_IDS.weight:
    case DL_FIELD_IDS.eyes:
    case DL_FIELD_IDS.restriction:
    case DL_FIELD_IDS.licenseNumber: {
      Component = (
        <Box sx={styles.credentialElement as SxProps}>
          <Typography sx={styles.fieldLabelStyle as SxProps}>
            {label}
          </Typography>
          <Typography sx={styles.fieldValueStyle as SxProps}>
            {value}
          </Typography>
        </Box>
      );
      break;
    }
    case DL_FIELD_IDS.dateOfBirth:
    case DL_FIELD_IDS.expiry:
    case DL_FIELD_IDS.issue: {
      let formattedDate = value;
      try {
        const time = new Date(value).getTime();
        formattedDate = dateFormat(time, 'mm / dd / yyyy');
      } catch (err) {
        console.warn('error while parsing date of issue', err);
      }
      Component = (
        <Box sx={styles.credentialElement as SxProps}>
          <Typography sx={styles.fieldLabelStyle as SxProps}>
            {label}
          </Typography>
          <Typography sx={styles.fieldValueStyle as SxProps}>
            {formattedDate}
          </Typography>
        </Box>
      );
      break;
    }
    case DL_FIELD_IDS.space: {
      Component = <Box />;
      break;
    }
    case DL_FIELD_IDS.organDonor: {
      Component =
        value === 1 ? (
          <Box sx={styles[`${fieldID}Container`] as SxProps}>
            <FavoriteIcon sx={styles[fieldID] as SxProps} />
            <Typography sx={styles.fieldValueStyle as SxProps}>
              Organ Donor
            </Typography>
          </Box>
        ) : null;
      break;
    }
  }

  return Component;
};

type DriveLicenseProps = {
  credentialsAsMap?: CredentialMap;
};

const DrivingLienseView: React.FC<DriveLicenseProps> = function ({
  credentialsAsMap,
}) {
  const renderAvatar = () =>
    avatarDisplayOrder?.map(fieldID => (
      <Grid item xs={12} key={fieldID}>
        {getComponent(fieldID, credentialsAsMap?.[fieldID])}
      </Grid>
    ));

  const renderProfile = () =>
    profileDisplayOrder?.map(fieldID => (
      <Grid item xs={6} key={fieldID}>
        {getComponent(fieldID, credentialsAsMap?.[fieldID])}
      </Grid>
    ));

  const renderCredentials = () => (
    <Grid item container xs={12} sx={styles.credentialContainer}>
      {credentialDisplayOrder?.map(fieldID => (
        <Grid item xs={6} key={fieldID}>
          {getComponent(fieldID, credentialsAsMap?.[fieldID])}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Container maxWidth="xs" sx={styles.container}>
      <Header />
      <RequestApprovedBanner />
      <Grid container sx={styles.gridContainer} spacing={0}>
        {renderAvatar()}
        {renderProfile()}
        {renderCredentials()}
      </Grid>
    </Container>
  );
};

DrivingLienseView.defaultProps = {
  credentialsAsMap: {},
};

export default DrivingLienseView;
