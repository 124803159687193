import React, { useMemo } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Header from "../../common/Header";
import RequestApprovedBanner from "../../common/RequestApprovedBanner";
import { CredentialMap, credentialsAsMapToAttributes } from "../util";

type Attributes = {
  ["ESG Report URL"]: string;
  ["Type"]: string;
};

function ESGReport({
  credentialsAsMap,
}: {
  credentialsAsMap: CredentialMap | undefined;
}) {
  const attributes = useMemo(
    () => credentialsAsMapToAttributes<Attributes>(credentialsAsMap),
    [credentialsAsMap]
  );

  const handleViewReport = () => {
    window.location.href = attributes["ESG Report URL"];
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
      disableGutters
    >
      <Header />
      <RequestApprovedBanner />
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Button variant="text" onClick={handleViewReport}>
          View Report
        </Button>
      </div>
    </Container>
  );
}

export default ESGReport;
