import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Box, Container } from '@mui/material';
import dateFormat from 'dateformat';
import EducationTranscript from './transcript/EducationTranscript';
import EducationCertificate from './certificate/EducationCertificate';
import Header from '../../../common/Header';
import RequestApprovedBanner from '../../../common/RequestApprovedBanner';
import { CredentialMap } from '../../util';

const styles = {
  container: {
    minHeight: '100%',
    padding: 0,
  },
  accordionHeaderStyle: {
    fontSize: 20,
    textAlign: 'left',
    color: '#424141',
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
  accordionDegreeStyle: {
    fontSize: 18,
    textAlign: 'left',
    color: '#424141',
    fontFamily: 'Poppins',
    fontWeight: 400,
  },
  accordionDetailStyle: {
    backgroundImage: `url(./assets/university-background.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 150,
    backgroundPositionX: 'right',
    backgroundSize: '70% auto',
    backgroundAttachment: 'scroll',
    padding: 0,
  },
};

const Accordion = styled((props: AccordionProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '1.5rem', transform: 'rotate(-90deg)' }}
      />
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

type EducationCertTranscriptProps = {
  credentialsAsMap?: CredentialMap;
};

const EducationCertTranscript: React.FC<EducationCertTranscriptProps> =
  function ({ credentialsAsMap }) {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
      (panel: string) =>
      (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
        window.scrollTo(0, 0);
      };

    return (
      <Container maxWidth="xs" sx={styles.container}>
        <Header />
        <RequestApprovedBanner />
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Box>
              <Typography sx={styles.accordionHeaderStyle}>
                Education Certificate
              </Typography>
              <Typography sx={styles.accordionDegreeStyle}>
                {dateFormat(
                  new Date(
                    credentialsAsMap?.dateofissue?.value?.value
                  ).getTime(),
                  'mmm dd, yyyy'
                )}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={styles.accordionDetailStyle}>
            <EducationCertificate
              credentialsAsMap={credentialsAsMap}
              isEmbeded
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Box>
              <Typography sx={styles.accordionHeaderStyle}>
                Official Transcript
              </Typography>
              <Typography sx={styles.accordionDegreeStyle}>
                {credentialsAsMap?.educationtitle?.value?.value}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={styles.accordionDetailStyle}>
            <EducationTranscript
              credentialsAsMap={credentialsAsMap}
              isEmbeded
            />
          </AccordionDetails>
        </Accordion>
      </Container>
    );
  };

EducationCertTranscript.defaultProps = {
  credentialsAsMap: {},
};

export default EducationCertTranscript;
