import React, { ReactNode } from 'react';
import {
  Container,
  Avatar,
  Grid,
  Box,
  Typography,
  SxProps,
} from '@mui/material';
import Header from '../../common/Header';
import RequestApprovedBanner from '../../common/RequestApprovedBanner';
import { bodyDisplayOrder, FIELD_IDS } from './types';
import { CredentialMap, makeDisplayName } from '../util';

const styles = {
  profilePicContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 7,
    my: 4,
  },
  profilePic: {
    height: 256,
    width: 256,
    borderRadius: '50%',
    border: 'solid 1px #E5E5EA',
  },
  name: {
    fontSize: 32,
    fontFamily: 'Poppins Medium',
    fontWeight: 500,
    color: '#13263B',
    textTransform: 'uppercase',
  },
  age: {
    fontSize: 64,
    fontFamily: 'Poppins SemiBold',
    fontWeight: 600,
    color: '#3274F3',
    lineHeight: 1,
  },
};
const getComponent = (
  fieldID: FIELD_IDS,
  credentialsAsMap: CredentialMap | undefined
): ReactNode => {
  let Component;
  const value = credentialsAsMap?.[fieldID]?.value?.value;

  switch (fieldID) {
    case FIELD_IDS.profilePic: {
      Component = (
        <Box sx={styles.profilePicContainer as SxProps}>
          <Avatar
            src={value}
            sx={styles.profilePic as SxProps}
            alt="Profile Picture"
          />
        </Box>
      );
      break;
    }
    case FIELD_IDS.name: {
      const displayName = makeDisplayName(credentialsAsMap);
      Component = (
        <Box>
          <Typography sx={styles.name as SxProps}>{displayName}</Typography>
        </Box>
      );
      break;
    }
    case FIELD_IDS.age: {
      Component = (
        <Box>
          <Typography sx={styles.age as SxProps}>18+</Typography>
        </Box>
      );
      break;
    }
    default:
      Component = null;
  }
  return Component;
};

type Props = {
  credentialsAsMap?: CredentialMap;
};

const Above18: React.FC<Props> = function ({ credentialsAsMap }) {
  const renderBody = () =>
    bodyDisplayOrder?.map(fieldID => (
      <Grid item xs={12} key={fieldID}>
        {getComponent(fieldID, credentialsAsMap)}
      </Grid>
    ));

  return (
    <Container maxWidth="xs" sx={{ p: 0, minHeight: '100vh' }}>
      <Header />
      <RequestApprovedBanner />
      <Grid container sx={{ p: 4 }} spacing={0}>
        {renderBody()}
      </Grid>
    </Container>
  );
};

Above18.defaultProps = {
  credentialsAsMap: {},
};

export default Above18;
