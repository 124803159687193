import React, { ReactNode } from 'react';
import {
  Typography,
  Grid,
  Box,
  Avatar,
  SxProps,
  Container,
} from '@mui/material';
import dateFormat from 'dateformat';
import NumberToWords from 'number-to-words';
import { CredentialMap } from '../../../util';
import { VerifiedCredential } from '../../../../../models/models';
import Header from '../../../../common/Header';
import RequestApprovedBanner from '../../../../common/RequestApprovedBanner';

import {
  bodyDisplayOrder,
  signatureDisplayOrder,
  UC_FIELD_IDS,
  UC_FIELD_DEFS,
} from './types';

const styles = {
  container: {
    minHeight: '100%',

    padding: 0,
  },
  gridContainer: {
    backgroundColor: 'rgba(248, 245, 237, 0.5)',
    color: '#000',
    pl: 4,
    pr: 4,
    backgroundImage: `url(./assets/university-background.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 150,
    backgroundPositionX: 'right',
    backgroundSize: '70% auto',
    backgroundAttachment: 'scroll',
  },
  gridContainerEmbedded: {
    color: '#000',
    backgroundColor: 'rgba(248, 245, 237, 0.5)',
    pl: 4,
    pr: 4,
  },
  fieldLabelStyle: {
    fontSize: 18,
    textAlign: 'center',
    color: '#777777',
    fontFamily: 'AndadaPro',
    fontWeight: 400,
    fontStyle: 'italic',
    textTransform: 'none',
    opacity: 1,
    mb: 1.5,
  },
  fieldValueStyle: {
    fontSize: 18,
    textAlign: 'center',
    color: '#3C3C3C',
    fontFamily: 'AndadaPro',
    fontWeight: 500,
    mb: 1.5,
  },
  [`${UC_FIELD_IDS.institueLogo}Container`]: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    my: 4,
  },
  [UC_FIELD_IDS.institueLogo]: {
    height: 106,
    width: 134,
    '& .MuiAvatar-img': {
      objectFit: 'contain',
    },
  },
  [`${UC_FIELD_IDS.issuedBy}Value`]: {
    mb: 3,
    color: '#3C3C3C',
    lineHeight: '27px',
    fontFamily: 'AndadaPro',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'left',
  },
  [`${UC_FIELD_IDS.content}Label`]: {
    textAlign: 'left',
    color: '#3C3C3C',
    lineHeight: '27px',
    mb: 3,
  },
  [`${UC_FIELD_IDS.institueName}Label`]: {
    mb: 0,
  },
  [`${UC_FIELD_IDS.institueName}Value`]: {
    color: '#3B3B3B',
    fontFamily: 'AndadaPro',
    fontWeight: 600,
    fontSize: 28,
    mb: 1,
    textTransform: 'uppercase',
  },
  [`${UC_FIELD_IDS.candidateName}Value`]: {
    fontFamily: 'AndadaPro',
    fontWeight: 800,
    fontSize: 21,
    textTransform: 'uppercase',
  },
  [`${UC_FIELD_IDS.educationTitle}Label`]: {
    mb: 1.5,
  },
  [`${UC_FIELD_IDS.educationTitle}Value`]: {
    mb: 3.5,
    fontFamily: 'AndadaPro',
    fontWeight: 600,
  },
  [`${UC_FIELD_IDS.dateOfIssue}Label`]: {
    mb: 0,
    textAlign: 'left',
    color: '#3C3C3C',
    textTransform: 'uppercase',
  },
  [`${UC_FIELD_IDS.dateOfIssue}Value`]: {
    textAlign: 'left',
    mb: 7,
    textTransform: 'uppercase',
    fontFamily: 'AndadaPro',
    fontStyle: 'italic',
    fontWeight: 400,
  },
  signatureContent: {
    mb: 4.5,
  },
  leftAlign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rightAlign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: 2,
  },
  signatureImage: {
    height: 58,
    width: 100,
    mb: 1.2,
    '& .MuiAvatar-img': {
      objectFit: 'contain',
    },
  },
  signatureTitle: {
    mb: 0.6,
    fontSize: 14,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
};

const getComponent = (
  fieldID: UC_FIELD_IDS,
  credential: VerifiedCredential | undefined,
  credentialsAsMap: CredentialMap | undefined
): ReactNode => {
  let Component;
  const value = credential?.value?.value;
  const label = UC_FIELD_DEFS[fieldID]?.label;

  switch (fieldID) {
    case UC_FIELD_IDS.signature1:
    case UC_FIELD_IDS.signature2:
    case UC_FIELD_IDS.signature3:
    case UC_FIELD_IDS.signature4: {
      const titleLines =
        credentialsAsMap?.[`${fieldID}title`]?.value?.value?.split(',');
      Component = (
        <Box sx={styles.signatureContent as SxProps}>
          <Avatar
            src={value}
            sx={styles.signatureImage}
            alt={`${titleLines?.[0]} Signature`}
            variant="square"
          >
            <Typography>Signature</Typography>
          </Avatar>
          {titleLines?.map((titleLine: string, index: number) => {
            const isLast = index === (titleLines?.length || 0) - 1;
            return (
              <Typography
                sx={[styles.fieldValueStyle, styles.signatureTitle] as SxProps}
                key={titleLine}
              >
                {titleLine}
                {!isLast && ','}
              </Typography>
            );
          })}
        </Box>
      );
      break;
    }
    case UC_FIELD_IDS.institueLogo: {
      Component = (
        <Box sx={styles[`${fieldID}Container`] as SxProps}>
          <Avatar
            src={value}
            alt="Institute Logo"
            sx={styles[fieldID]}
            variant="square"
          >
            {/* fallback if not provided or not consented. 
            Will be displayed as box with the below text */}
            <Typography>Institute Logo</Typography>
          </Avatar>
        </Box>
      );
      break;
    }
    case UC_FIELD_IDS.issuedBy: {
      Component = (
        <Box sx={styles.issuedByContainer as SxProps}>
          <Typography sx={styles?.[`${fieldID}Value`] as SxProps}>
            {UC_FIELD_DEFS[fieldID]?.valuePrefix} {value}{' '}
            {UC_FIELD_DEFS[fieldID]?.valueSuffix}
          </Typography>
        </Box>
      );
      break;
    }
    case UC_FIELD_IDS.content: {
      const formattedLabel =
        typeof label === 'string' ? label.replace(/\s+/g, ' ') : label;
      Component = (
        <Box>
          <Typography
            sx={[styles.fieldLabelStyle, styles[`${fieldID}Label`]] as SxProps}
          >
            {formattedLabel}
          </Typography>
        </Box>
      );
      break;
    }
    case UC_FIELD_IDS.dateOfIssue: {
      let formattedDate = value;
      try {
        const time = new Date(value).getTime();
        const monthYear = dateFormat(time, 'mmm, yyyy');
        const dateWords = NumberToWords.toWordsOrdinal(dateFormat(time, 'dd'));
        formattedDate = `${dateWords} of ${monthYear}`;
      } catch (err) {
        console.warn('error while parsing date of issue', err);
      }

      Component = (
        <Box>
          <Typography
            sx={[styles.fieldLabelStyle, styles[`${fieldID}Label`]] as SxProps}
          >
            {label}
          </Typography>
          <Typography
            sx={[styles.fieldValueStyle, styles[`${fieldID}Value`]] as SxProps}
          >
            {formattedDate}
          </Typography>
        </Box>
      );
      break;
    }
    default:
      Component = (
        <Box>
          <Typography
            sx={
              [styles.fieldLabelStyle, styles?.[`${fieldID}Label`]] as SxProps
            }
          >
            {label}
          </Typography>
          <Typography
            sx={
              [styles.fieldValueStyle, styles?.[`${fieldID}Value`]] as SxProps
            }
          >
            {value}
          </Typography>
        </Box>
      );

      break;
  }

  return Component;
};

type EducationCertificateProps = {
  credentialsAsMap?: CredentialMap;
  isEmbeded?: boolean;
};

const EducationCertificate: React.FC<EducationCertificateProps> = function ({
  credentialsAsMap,
  isEmbeded,
}) {
  const renderBody = () =>
    bodyDisplayOrder?.map(fieldID => (
      <Grid item xs={12} key={fieldID}>
        {getComponent(fieldID, credentialsAsMap?.[fieldID], credentialsAsMap)}
      </Grid>
    ));

  const renderSignatures = () => {
    const displayOrder = [...signatureDisplayOrder];

    // arrange rows with 2 columns
    const displayOrderInCols = [];
    while (displayOrder?.length) {
      displayOrderInCols.push(displayOrder.splice(0, 2));
    }

    return displayOrderInCols?.map(colIDS => (
      <Grid container key={colIDS?.join(',')} spacing={0}>
        {colIDS?.map((colID, index) => (
          <Grid
            item
            key={colID}
            xs={6}
            sx={index % 2 === 0 ? styles.leftAlign : styles.rightAlign}
          >
            {getComponent(colID, credentialsAsMap?.[colID], credentialsAsMap)}
          </Grid>
        ))}
      </Grid>
    ));
  };

  return (
    <Container maxWidth="xs" disableGutters sx={styles.container}>
      {isEmbeded ? null : (
        <div>
          <Header />
          <RequestApprovedBanner />
        </div>
      )}
      <Grid
        container
        sx={isEmbeded ? styles.gridContainerEmbedded : styles.gridContainer}
        spacing={0}
      >
        {renderBody()}
        {renderSignatures()}
      </Grid>
    </Container>
  );
};

EducationCertificate.defaultProps = {
  credentialsAsMap: {},
  isEmbeded: false,
};

export default EducationCertificate;
