import React from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import Div100vh from 'react-div-100vh';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Header from '../components/common/Header';
import Divider from '../components/Divider';

import { poppinsRegular, poppinsBold, poppinsSemiBold } from '../themes/themes';
import { getPlatformConfig } from '../utils/platform';

const styles = {
  container: {
    p: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  innerContainer: {
    p: 4,
    pt: 5,
    flexGrow: 1,
  },
  request: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 4,
    color: 'rgba(19, 38, 59, 0.6)',
  },
  waiting: {
    fontSize: 36,
    fontWeight: 700,
    color: '#3274F3',
    lineHeight: 1,
    mb: 14.4,
  },
  close: {
    fontSize: 18,
    fontWeight: 600,
    color: '#13263B',
    mb: 2.6,
  },
  appContainer: {
    p: 4,
    backgroundColor: '#3274F3',
    color: '#FFFFFF',
  },
  waitingLong: {
    fontWeight: 700,
    fontSize: 18,
    mb: 2.6,
  },
  signup: {
    fontWeight: 400,
    fontSize: 16,
    mb: 3.5,
  },
  storeIcon: {
    width: 157,
    height: 46.92,
  },
};

const sentRequestText = 'Sent the request';
const waitingText = `Waiting for the user's consent`;
const closeText = `Please don't close this page`;
const waitingLongText = 'Waiting for too long ...';
const signupText =
  'Sign up with our app to receive notifications and view the content anytime anywhere';

export default function WaitForConsent() {
  const renderStore = () => {
    const { url, icon } = getPlatformConfig();

    return icon && url ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={icon} style={styles.storeIcon} alt="Store Icon" />
      </a>
    ) : null;
  };

  return (
    <Div100vh>
      <Container
        maxWidth="xs"
        sx={styles.container}
        className="States-Container"
      >
        <Divider />
        <Header />
        <Divider />

        <ThemeProvider theme={poppinsBold}>
          <Box sx={styles.innerContainer}>
            <ThemeProvider theme={poppinsRegular}>
              <Typography sx={styles.request}>{sentRequestText}</Typography>
            </ThemeProvider>
            <Typography sx={styles.waiting}>{waitingText}</Typography>
          </Box>
          <Box sx={{ px: 4 }}>
            <ThemeProvider theme={poppinsSemiBold}>
              <Typography sx={styles.close}>{closeText}</Typography>
            </ThemeProvider>
          </Box>
          <Box sx={styles.appContainer}>
            <Typography sx={styles.waitingLong}>{waitingLongText}</Typography>
            <ThemeProvider theme={poppinsRegular}>
              <Typography sx={styles.signup}>{signupText}</Typography>
            </ThemeProvider>
            {renderStore()}
          </Box>
        </ThemeProvider>
      </Container>
    </Div100vh>
  );
}
