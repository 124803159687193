import React, { ReactNode } from 'react';
import {
  Typography,
  Grid,
  Box,
  Avatar,
  SxProps,
  Container,
} from '@mui/material';
import dateFormatter from 'dateformat';
import { ThemeProvider } from '@mui/material/styles';
import { CredentialMap } from '../../util';
import { VerifiedCredential } from '../../../../models/models';
import Header from '../../../common/Header';
import RequestApprovedBanner from '../../../common/RequestApprovedBanner';
import { ReactComponent as CheckmarkIcon } from '../../../../assets/icons/checkmark.svg';
import { roboto } from '../../../../themes/themes';

import {
  FIELD_IDS,
  FIELD_DEFS,
  HEALTH_CODES,
  organizationDisplayOrder,
  patientDisplayOrder,
  vaccineDosesDisplayOrder,
  vaccineDetailsDisplayOrder,
  healthCodeDisplayOrder,
} from './types';

const styles = {
  container: {
    minHeight: '100%',
    p: 0,
  },
  gridContainer: {
    color: '#000',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 320,
    backgroundPositionX: 'right',
    backgroundSize: '50% auto',
    backgroundAttachment: 'scroll',
  },
  summaryContainer: {
    p: 3.5,
    pt: 6.25,
    flex: 1,
  },
  vaccinationContainer: {
    pt: 5,
    pl: 3.5,
    pr: 3.5,
  },
  vaccineDosesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  vaccineDosesInnerContainer: {
    display: 'flex',
    flex: 1,
  },
  fieldLabelStyle: {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 400,
    opacity: 0.78,
    textTransform: 'none',
  },
  fieldValueStyle: {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 700,
    mb: 2.5,
    textTransform: 'none',
  },
  patientDetails: {
    color: '#FFFFFF',
  },
  vaccineDetails: {
    color: '#000000',
  },
  orgContainer: {
    mb: 4.25,
    display: 'flex',
    flexDirection: 'row',
  },
  [`${FIELD_IDS.organizationLogo}Col`]: {
    pr: 2,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`${FIELD_IDS.organizationLogo}`]: {
    width: 107,
    height: 107,
  },
  [`${FIELD_IDS.organizationName}Container`]: {
    justifyContent: 'center',
  },
  [`${FIELD_IDS.organizationName}Value`]: {
    fontSize: 32,
    mb: 0,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  [`${FIELD_IDS.name}Value`]: {
    textTransform: 'uppercase',
  },
  [`${FIELD_IDS.qrCode}Container`]: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    mb: 2,
  },
  [`${FIELD_IDS.qrCode}`]: {
    width: 181,
    height: 181,
  },
  dosesTitle: {
    fontWeight: 700,
    fontSize: 20,
    color: '#000000',
    mb: 1.6,
  },
  doseContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    mb: 5,
  },
  doseText: {
    fontSize: 16,
    fontWeight: 400,
    ml: 1.25,
  },
  doseTaken: {
    color: '#4D8CDF',
  },
  doseNotTaken: {
    color: 'rgba(0, 0, 0, 0.2)',
  },
  healthCodeContainer: {
    p: 3.5,
    pt: 2.5,
    pb: 1.25,
    mb: 4,
    backgroundColor: 'rgba(75,175,118,1)',
  },
  [HEALTH_CODES.green]: {
    backgroundColor: 'rgba(75,175,118,1)',
  },
  [HEALTH_CODES.red]: {
    backgroundColor: 'rgba(217,91,74,1)',
  },
  [HEALTH_CODES.yellow]: {
    backgroundColor: 'rgba(233,162,0,1)',
  },
  [HEALTH_CODES.blue]: {
    backgroundColor: 'rgba(76,116,192,1)',
  },
  [HEALTH_CODES.purple]: {
    backgroundColor: 'purple',
  },
  [`${FIELD_IDS.healthCodeTitle}Value`]: {
    mb: 1,
    fontWeight: 700,
  },
  [`${FIELD_IDS.healthCodeDescription}Value`]: {
    fontWeight: 400,
  },
  [`${FIELD_IDS.healthCodeEffectiveTime}Container`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  [`${FIELD_IDS.healthCodeEffectiveTime}Value`]: {
    color: 'rgba(255, 255, 255, 0.78)',
    fontWeight: 400,
  },
  bgColorBlue: {
    backgroundColor: 'rgba(3, 45, 115, 0.8)',
  },
};

const getDefaultComponent = (
  fieldID: string,
  label: string | undefined,
  value: string | number | boolean | undefined,
  componentStyles: SxProps
) => (
  <>
    <Typography
      sx={
        [
          styles.fieldLabelStyle,
          componentStyles,
          styles?.[`${fieldID}Label`],
        ] as SxProps
      }
    >
      {label}
    </Typography>
    <Typography
      sx={
        [
          styles.fieldValueStyle,
          componentStyles,
          styles?.[`${fieldID}Value`],
        ] as SxProps
      }
    >
      {value}
    </Typography>
  </>
);

const getDateComponent = (
  fieldID: string,
  label: string | undefined,
  value: string,
  format: string,
  componentStyles: SxProps
) => {
  let formattedDate = value;
  try {
    formattedDate = dateFormatter(value, format);
  } catch (err) {
    console.warn('error while parsing health code effective time', err);
  }

  return getDefaultComponent(fieldID, label, formattedDate, componentStyles);
};

const getComponent = (
  fieldID: FIELD_IDS,
  credential: VerifiedCredential | undefined,
  componentStyles: SxProps
): ReactNode => {
  let Component;
  const value = credential?.value?.value;
  const label = FIELD_DEFS[fieldID]?.label;

  switch (fieldID) {
    case FIELD_IDS.organizationName: {
      Component = (
        <Box sx={styles[`${fieldID}Container`] as SxProps}>
          <Typography
            sx={[componentStyles, styles[`${fieldID}Value`]] as SxProps}
          >
            {value}
          </Typography>
          <Typography
            sx={[componentStyles, styles[`${fieldID}Value`]] as SxProps}
          >
            {label}
          </Typography>
        </Box>
      );
      break;
    }
    case FIELD_IDS.qrCode:
      Component = (
        <Avatar
          src={value}
          sx={styles[fieldID] as SxProps}
          alt={label}
          variant="square"
        >
          <Typography>{label}</Typography>
        </Avatar>
      );
      break;

    case FIELD_IDS.organizationLogo: {
      Component = (
        <Avatar src={value} sx={styles[fieldID] as SxProps} alt={label}>
          <Typography>{label}</Typography>
        </Avatar>
      );
      break;
    }
    case FIELD_IDS.vaccineDose1:
    case FIELD_IDS.vaccineDose2:
    case FIELD_IDS.vaccineBooster: {
      const isDoseTaken = value?.toLowerCase() === 'yes';
      const styleToUse = isDoseTaken ? styles.doseTaken : styles.doseNotTaken;
      Component = (
        <Box sx={styles.doseContainer}>
          <CheckmarkIcon color={styleToUse?.color} />
          <Typography sx={[styles.doseText, styleToUse] as SxProps}>
            {label}
          </Typography>
        </Box>
      );
      break;
    }
    case FIELD_IDS.healthCodeDescription:
    case FIELD_IDS.healthCodeTitle: {
      Component = (
        <Typography
          sx={
            [
              styles.fieldValueStyle,
              componentStyles,
              styles[`${fieldID}Value`],
            ] as SxProps
          }
        >
          {value}
        </Typography>
      );
      break;
    }
    case FIELD_IDS.dateOfBirth: {
      Component = getDateComponent(
        fieldID,
        label,
        value,
        'dd - mmm - yyyy',
        componentStyles
      );
      break;
    }

    case FIELD_IDS.dateOfVaccination: {
      Component = getDateComponent(
        fieldID,
        label,
        value,
        'yyyy-mm-dd',
        componentStyles
      );
      break;
    }
    case FIELD_IDS.healthCodeEffectiveTime: {
      Component = (
        <Box sx={styles[`${fieldID}Container`] as SxProps}>
          {getDateComponent(
            fieldID,
            label,
            value,
            'yyyy-mm-dd HH:MM',
            componentStyles
          )}
        </Box>
      );
      break;
    }
    default:
      Component = getDefaultComponent(fieldID, label, value, componentStyles);
      break;
  }

  return Component;
};

type Props = {
  credentialsAsMap?: CredentialMap;
};

const CovidCertificate: React.FC<Props> = function ({
  credentialsAsMap: credentials,
}) {
  const renderOrg = () => (
    <Box sx={styles.orgContainer}>
      {organizationDisplayOrder?.map(fieldID => (
        <Box key={fieldID} sx={styles[`${fieldID}Col`] as SxProps}>
          {getComponent(fieldID, credentials?.[fieldID], styles.patientDetails)}
        </Box>
      ))}
    </Box>
  );

  const renderPatientDetails = () => (
    <Box sx={styles.patientDetailsContainer as SxProps}>
      {patientDisplayOrder?.map(fieldID => (
        <Box key={fieldID}>
          {getComponent(fieldID, credentials?.[fieldID], styles.patientDetails)}
        </Box>
      ))}
    </Box>
  );

  const renderSummary = () => (
    <Grid item sx={[styles.summaryContainer, styles.bgColorBlue]}>
      {renderOrg()}
      {renderPatientDetails()}
    </Grid>
  );

  const renderVaccineDoses = () => (
    <Box>
      <Typography sx={[styles.dosesTitle]}>COVID-19 VACCINATION</Typography>
      <Box sx={styles.vaccineDosesContainer}>
        {vaccineDosesDisplayOrder?.map(fieldID => (
          <Box key={fieldID} sx={styles.vaccineDosesInnerContainer}>
            {getComponent(
              fieldID,
              credentials?.[fieldID],
              styles.vaccineDetails
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );

  const renderVaccineDetails = () =>
    vaccineDetailsDisplayOrder?.map(fieldID => (
      <Box key={fieldID}>
        {getComponent(fieldID, credentials?.[fieldID], styles.vaccineDetails)}
      </Box>
    ));

  const renderVaccinationDetails = () => (
    <Grid item sx={styles.vaccinationContainer}>
      {renderVaccineDoses()}
      {renderVaccineDetails()}
    </Grid>
  );

  const renderQRCode = () => (
    <Grid item sx={styles.qrcodeContainer as SxProps}>
      {credentials?.[FIELD_IDS.qrCode]?.value?.value &&
        getComponent(FIELD_IDS.qrCode, credentials?.[FIELD_IDS.qrCode], {})}
    </Grid>
  );

  const renderHealthCode = () => (
    <Grid
      item
      sx={
        [
          styles.healthCodeContainer,
          styles[
            credentials?.[FIELD_IDS.healthCode]?.value?.value?.toLowerCase()
          ],
        ] as SxProps
      }
    >
      {healthCodeDisplayOrder?.map(fieldID => (
        <Box key={fieldID}>
          {getComponent(fieldID, credentials?.[fieldID], styles.patientDetails)}
        </Box>
      ))}
    </Grid>
  );

  return (
    <ThemeProvider theme={roboto}>
      <Container maxWidth="xs" disableGutters sx={styles.container}>
        <Header />
        <RequestApprovedBanner />
        <Grid
          container
          sx={
            [
              styles.gridContainer,
              {
                backgroundImage: `url(${
                  credentials?.[FIELD_IDS.holographicBackgroundImage]?.value
                    ?.value
                })`,
              },
            ] as SxProps
          }
          spacing={0}
        >
          {renderSummary()}
          {renderVaccinationDetails()}
          {renderHealthCode()}
          {renderQRCode()}
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

CovidCertificate.defaultProps = {
  credentialsAsMap: {},
};

export default CovidCertificate;
