import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { ReactComponent as RemoveIcon } from '../assets/icons/remove.svg';
import Header from '../components/common/Header';
import Divider from '../components/Divider';
import { poppinsRegular, poppinsBold } from '../themes/themes';

const styles = {
  container: {
    p: 0,
    flex: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainer: {
    p: 4,
  },
  iconContainer: {
    mt: 16,
    mb: 3,
  },
  expired: {
    fontSize: 20,
    color: 'rgba(19, 38, 59, 0.6)',
    fontWeight: 400,
    mb: 1.25,
  },
  error: {
    color: '#13263B',
    fontWeight: 600,
    fontSize: 36,
  },
};

const qrCodeErrMessage = 'QR code has expired';
const defaultErrMessage = 'Oops! Unable to process your request';
const pageNotFound = 'Page not found';

type Props = {
  error?: string;
};

const PageNotFound: React.FC<Props> = function ({ error }: Props) {
  const showQRCodeError =
    typeof error === 'string' && error.includes('expired');

  return (
    <ThemeProvider theme={poppinsBold}>
      <Container maxWidth="xs" sx={styles.container}>
        <Divider />
        <Header />
        <Divider />
        <Box sx={styles.innerContainer}>
          <Box sx={styles.iconContainer}>
            <RemoveIcon />
          </Box>
          {showQRCodeError ? (
            <>
              <ThemeProvider theme={poppinsRegular}>
                <Typography sx={styles.expired}>{qrCodeErrMessage}</Typography>
              </ThemeProvider>

              <Typography sx={styles.error}>{pageNotFound}</Typography>
            </>
          ) : (
            <Typography sx={styles.error}>{defaultErrMessage}</Typography>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

PageNotFound.defaultProps = {
  error: undefined,
};

export default PageNotFound;
