import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

export default function Header() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        px: 4,
        py: 2,
      }}
    >
      <Avatar
        alt="Logo"
        variant="square"
        src="./trustgrid-logo.png"
        sx={{ width: 150, height: 19 }}
      />
    </Box>
  );
}
