/* eslint-disable no-unused-vars */
export type VerifiedCredential = {
  name: string;
  value: { value: any; validated: boolean };
};

export interface VerificationResponse {
  status: string;
  verifiedCredentials?: VerifiedCredential[];
}

export interface VerifiedCredentials {
  credentials?: VerifiedCredential[];
}

export enum ConsentState {
  Waiting = 'waiting',
  Pending = 'pending',
  Granted = 'granted',
  Rejected = 'rejected',
  Invalid = 'invalid',
}
export interface Course {
  Title: string;
  Grade: string;
  GPA: number;
}

export interface Semester {
  Title: string;
  'Hours Passed': number;
  'Hours Completed': number;
  'GPA Hours': number;
  'GR Points': number;
  Courses: Course[];
}
