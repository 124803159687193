/* eslint-disable no-unused-vars */
// keys to be in lower case and without whitespace
// all keys received from backend will be normalized (i.e converted
// into lower case and without whitespace)
// verified Credentials lookup will happen with normalized keys

export enum DL_FIELD_IDS {
  profilePic = 'profilepic',
  signature = 'signature',
  cardNumber = 'cardnumber',
  givenName = 'givenname(s)',
  surName = 'surname',
  licenseNumber = 'licensenumber',
  address = 'address',
  issue = 'issue',
  expiry = 'expiry',
  class = 'class',
  endorsement = 'endorsement',
  restriction = 'restriction',
  dateOfBirth = 'dateofbirth',
  sex = 'sex',
  height = 'height',
  weight = 'weight',
  eyes = 'eyes',
  organDonor = 'organdonor',
  space = 'space',
}

export type DL_FIELD_DEF_TYPE = {
  [key: string]: {
    label?: string;
    valuePrefix?: string;
    valueSuffix?: string;
  };
};

export const DL_FIELD_DEFS: DL_FIELD_DEF_TYPE = {
  [DL_FIELD_IDS.licenseNumber]: {
    label: 'License Number',
  },
  [DL_FIELD_IDS.address]: {
    label: 'Address',
  },
  [DL_FIELD_IDS.issue]: {
    label: 'Date of issue',
  },
  [DL_FIELD_IDS.expiry]: {
    label: 'Date of expiry',
  },
  [DL_FIELD_IDS.class]: {
    label: 'Class',
  },
  [DL_FIELD_IDS.endorsement]: {
    label: 'END',
  },
  [DL_FIELD_IDS.restriction]: {
    label: 'RES',
  },
  [DL_FIELD_IDS.dateOfBirth]: {
    label: 'Date of birth',
  },
  [DL_FIELD_IDS.sex]: {
    label: 'Sex',
  },
  [DL_FIELD_IDS.height]: {
    label: 'HGT',
  },
  [DL_FIELD_IDS.weight]: {
    label: 'WGT',
  },
  [DL_FIELD_IDS.eyes]: {
    label: 'EYES',
  },
  [DL_FIELD_IDS.organDonor]: {
    label: '',
  },
};

export const avatarDisplayOrder = [
  DL_FIELD_IDS.profilePic,
  DL_FIELD_IDS.signature,
  DL_FIELD_IDS.cardNumber,
  DL_FIELD_IDS.givenName,
  DL_FIELD_IDS.surName,
  DL_FIELD_IDS.licenseNumber,
  DL_FIELD_IDS.address,
];

export const profileDisplayOrder = [
  DL_FIELD_IDS.issue,
  DL_FIELD_IDS.expiry,
  DL_FIELD_IDS.class,
  DL_FIELD_IDS.space,
  DL_FIELD_IDS.endorsement,
  DL_FIELD_IDS.restriction,
];

export const credentialDisplayOrder = [
  DL_FIELD_IDS.dateOfBirth,
  DL_FIELD_IDS.sex,
  DL_FIELD_IDS.height,
  DL_FIELD_IDS.weight,
  DL_FIELD_IDS.eyes,
  DL_FIELD_IDS.organDonor,
];
