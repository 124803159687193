/* eslint-disable no-unused-vars */
// keys to be in lower case and without whitespace
// all keys received from backend will be normalized (i.e converted
// into lower case and without whitespace)
// verified Credentials lookup will happen with normalized keys

export enum FIELD_IDS {
  profilePic = 'profilepic',
  name = 'name',
  age = 'above18', // for UI use
}

export const bodyDisplayOrder = [
  FIELD_IDS.profilePic,
  FIELD_IDS.name,
  FIELD_IDS.age,
];
