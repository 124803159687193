import React from 'react';
import Container from '@mui/material/Container';
import { CredentialMap } from '../util';

type Props = {
  credentialsAsMap?: CredentialMap;
};

const GenericIdentity: React.FC<Props> = function ({ credentialsAsMap = {} }) {
  return (
    <Container
      maxWidth="xs"
      sx={{
        alignItems: 'center',
        flex: 1,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <u>Verified Credentials</u>
      <ol
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          paddingLeft: 40,
          paddingRight: 40,
        }}
      >
        {Object.values(credentialsAsMap)?.map(credential => (
          <li
            style={{
              fontSize: 16,
              textAlign: 'left',
              wordBreak: 'break-all',
            }}
          >
            {credential?.name}: {credential?.value?.value}
          </li>
        ))}
      </ol>
    </Container>
  );
};

GenericIdentity.defaultProps = {
  credentialsAsMap: {},
};

export default GenericIdentity;
