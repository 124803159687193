import React, { ReactNode, Fragment } from 'react';
import {
  Typography,
  Grid,
  Box,
  Avatar,
  SxProps,
  Container,
} from '@mui/material';
import dateFormat from 'dateformat';
import { CredentialMap } from '../../../util';
import {
  VerifiedCredential,
  Semester,
  Course,
} from '../../../../../models/models';
import Header from '../../../../common/Header';
import RequestApprovedBanner from '../../../../common/RequestApprovedBanner';

import {
  candidateDisplayOrder,
  termsDisplayOrder,
  signatureDisplayOrder,
  privacyDisplayOrder,
  TS_FIELD_IDS,
  TS_FIELD_DEFS,
} from './types';

const graduateSchool = 'Graduate School >>>>>>';
const hoursUndertaken = 'Hrs undertaken: ';
const hoursPassed = 'Hrs passed: ';
const GPAHours = 'GPA Hrs: ';
const GRPoints = 'GR PTS: ';
const GPA = 'GPA: ';

const accumulatedHeader =
  'Cumulative totals earned as a graduate student at U.';

const styles = {
  container: {
    minHeight: '100%',
    padding: 0,
  },
  gridContainer: {
    color: '#000',
    backgroundImage: `url(./assets/university-background.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 150,
    backgroundPositionX: 'right',
    backgroundSize: '70% auto',
  },
  gridContainerEmbedded: {
    color: '#000',
  },
  yellowgroundContainer: {
    backgroundColor: '#FFFDF5',
    zIndex: -1,
    pl: 5,
    pr: 5,
  },
  yellowgroundContainerEmbedded: {
    pl: 5,
    pr: 5,
    backgroundColor: 'rgba(248, 245, 237, 0.5)',
    width: '100%',
  },
  whitegroundContainer: {
    pl: 5,
    pr: 5,
  },
  whitegroundContainerEmbedded: {
    pl: 5,
    pr: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  fieldLabelStyle: {
    fontSize: 18,
    textAlign: 'center',
    color: '#3C3C3C',
    fontFamily: 'AndadaPro',
    fontWeight: 400,
    textTransform: 'none',
    opacity: 1,
  },
  fieldValueStyle: {
    fontSize: 18,
    textAlign: 'center',
    color: '#424141',
    fontFamily: 'AndadaPro',
    fontWeight: 500,
  },
  [`${TS_FIELD_IDS.institueName}Value`]: {
    color: '#3C3C3C',
    fontFamily: 'AndadaPro',
    fontWeight: 800,
    fontSize: 24,
    textTransform: 'uppercase',
    textAlign: 'left',
    mb: 1,
    mt: 2,
  },
  [`${TS_FIELD_IDS.institueAddress}Value`]: {
    color: '#3C3C3C',
    fontFamily: 'AndadaPro',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'left',
    mb: 5,
  },
  [`${TS_FIELD_IDS.courseWork}Label`]: {
    fontFamily: 'AndadaPro',
    fontWeight: 500,
    fontSize: 21,
    textAlign: 'left',
    mt: 0,
    mb: 3,
  },
  [`${TS_FIELD_IDS.institueLogo}Container`]: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    my: 4,
  },
  [TS_FIELD_IDS.institueLogo]: {
    height: 106,
    width: 134,
    '& .MuiAvatar-img': {
      objectFit: 'contain',
    },
  },
  signatureContent: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    my: 4,
  },
  cumulativeContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
  },
  signatureImage: {
    height: 58,
    width: 100,
    mb: 1.2,
    '& .MuiAvatar-img': {
      objectFit: 'contain',
    },
  },
  signatureTitle: {
    fontSize: 14,
    textAlign: 'left',
  },
  poppinsWeight400Size18: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'left',
  },
  poppinsWeight500Size18: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'left',
  },
  poppinsWeight600Size18: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 18,
    textAlign: 'left',
  },
  poppinsWeight700Size18: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 18,
    textAlign: 'left',
  },
  andadaProWeight500Size18: {
    fontFamily: 'AndadaPro',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'left',
  },
  andadaProWeight700Size21: {
    fontFamily: 'AndadaPro',
    fontWeight: 700,
    fontSize: 21,
    textAlign: 'left',
  },
  andadaProWeight400Size18Italic: {
    fontFamily: 'AndadaPro',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'left',
    fontStyle: 'italic',
  },
  greyColor: {
    color: '#3C3C3C',
  },
  greyColorAlpha78: {
    color: '#3C3C3C80',
  },
  mt1: {
    mt: 1,
  },
  mt2: {
    mt: 2,
  },
  mt4: {
    mt: 4,
  },
  mb1: {
    mb: 1,
  },
  mb2: {
    mb: 2,
  },
  mb3: {
    mb: 3,
  },
  mb4: {
    mb: 4,
  },
  my1: {
    my: 1,
  },
  my2: {
    my: 2,
  },
  my3: {
    my: 3,
  },
};

const getComponent = (
  fieldID: TS_FIELD_IDS,
  credential: VerifiedCredential | undefined,
  credentialsAsMap: CredentialMap | undefined
): ReactNode => {
  let Component;
  const value = credential?.value?.value;
  const label = TS_FIELD_DEFS[fieldID]?.label;

  switch (fieldID) {
    case TS_FIELD_IDS.candidateName:
    case TS_FIELD_IDS.id: {
      Component = (
        <Box sx={styles.mb2 as SxProps}>
          <Typography
            sx={[styles.poppinsWeight400Size18, styles.greyColor] as SxProps}
          >
            {label}
          </Typography>
          <Typography sx={styles.poppinsWeight700Size18 as SxProps}>
            {value}
          </Typography>
        </Box>
      );
      break;
    }
    case TS_FIELD_IDS.endOfTranscript: {
      Component = (
        <Box sx={styles.mt4 as SxProps}>
          <Typography
            sx={[styles.andadaProWeight700Size21, styles.greyColor] as SxProps}
          >
            {label}
          </Typography>
        </Box>
      );
      break;
    }
    case TS_FIELD_IDS.privacy: {
      Component = (
        <Box sx={styles.mb4 as SxProps}>
          <Typography
            sx={
              [
                styles.andadaProWeight400Size18Italic,
                styles.greyColor,
              ] as SxProps
            }
          >
            {label}
          </Typography>
        </Box>
      );
      break;
    }
    case TS_FIELD_IDS.conferred: {
      let formattedDate = credentialsAsMap?.dateofissue?.value?.value;
      try {
        const time = new Date(formattedDate).getTime();
        formattedDate = dateFormat(time, 'mmmm dd, yyyy');
      } catch (err) {
        console.warn('error while parsing date of issue', err);
      }

      Component = (
        <Box sx={styles.mb3 as SxProps}>
          <Typography
            sx={
              [
                styles.andadaProWeight400Size18Italic,
                styles.greyColor,
              ] as SxProps
            }
          >
            {label} {formattedDate}
          </Typography>
        </Box>
      );
      break;
    }
    case TS_FIELD_IDS.dateOfBirth: {
      let formattedDate = value;
      try {
        const time = new Date(value).getTime();
        formattedDate = dateFormat(time, 'mmm dd, yyyy');
      } catch (err) {
        console.warn('error while parsing date of issue', err);
      }
      Component = (
        <Box sx={styles.mb2 as SxProps}>
          <Typography
            sx={[styles.poppinsWeight400Size18, styles.greyColor] as SxProps}
          >
            {label}
          </Typography>
          <Typography sx={styles.poppinsWeight700Size18 as SxProps}>
            {formattedDate}
          </Typography>
        </Box>
      );
      break;
    }
    case TS_FIELD_IDS.institueLogo: {
      Component = (
        <Box sx={styles[`${fieldID}Container`] as SxProps}>
          <Avatar
            src={value}
            alt="Institute Logo"
            sx={styles[fieldID] as SxProps}
            variant="square"
          >
            {/* fallback if not provided or not consented. 
            Will be displayed as box with the below text */}
            <Typography>Institute Logo</Typography>
          </Avatar>
        </Box>
      );
      break;
    }
    case TS_FIELD_IDS.signature1: {
      const titleLines =
        credentialsAsMap?.[`${fieldID}title`]?.value?.value?.split(',');

      Component = (
        <Box sx={styles.signatureContent as SxProps}>
          <Avatar
            src={value}
            sx={styles.signatureImage}
            alt={`${titleLines?.[0]} Signature`}
            variant="square"
          >
            <Typography>Signature</Typography>
          </Avatar>
          {titleLines?.map((titleLine: string, index: number) => {
            const isLast = index === (titleLines?.length || 0) - 1;
            return (
              <Typography
                sx={[styles.fieldValueStyle, styles.signatureTitle] as SxProps}
                key={titleLine}
              >
                {titleLine}
                {!isLast && ','}
              </Typography>
            );
          })}
        </Box>
      );
      break;
    }
    case TS_FIELD_IDS.terms: {
      let totalHrsCompleted = 0;
      let totalHrsPassed = 0;
      let totalGPAHrs = 0;
      let totalGRPTS = 0;
      let totalCourse = 0;
      let totalGPA = 0;
      const termsString = credentialsAsMap?.[`${fieldID}`]?.value?.value;

      if (termsString === undefined) {
        Component = (
          <Box sx={styles.andadaProWeight700Size21 as SxProps}>loading...</Box>
        );
      } else {
        const semesters: Semester[] = JSON.parse(termsString);

        Component = (
          <Grid item xs={12}>
            {semesters?.map((sem: Semester) => {
              const courses: Course[] = sem.Courses;
              const courseCount = courses.length;
              const GPASum = courses.reduce(
                (acc, course) => acc + course.GPA,
                0
              );

              totalHrsCompleted += sem['Hours Completed'];
              totalHrsPassed += sem['Hours Passed'];
              totalGPAHrs += sem['GPA Hours'];
              totalGRPTS += sem['GR Points'];
              totalCourse += courseCount;
              totalGPA += GPASum;

              return (
                <Grid item container spacing={0} xs={12} key={sem.Title}>
                  <Grid item xs={12} sx={styles.mb1 as SxProps}>
                    <Typography sx={styles.andadaProWeight700Size21 as SxProps}>
                      {sem.Title} <br /> {graduateSchool}
                    </Typography>
                  </Grid>
                  <Grid item container spacing={2} xs={12}>
                    {courses.map((course: Course) => (
                      <Fragment key={course.Title}>
                        <Grid item xs={9}>
                          <Typography
                            sx={
                              [
                                styles.poppinsWeight400Size18,
                                styles.greyColor,
                              ] as SxProps
                            }
                          >
                            {course.Title}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            sx={
                              [
                                styles.poppinsWeight400Size18,
                                styles.greyColor,
                              ] as SxProps
                            }
                          >
                            {course.GPA.toFixed(1)}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            sx={
                              [
                                styles.poppinsWeight400Size18,
                                styles.greyColor,
                              ] as SxProps
                            }
                          >
                            {course.Grade}
                          </Typography>
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                  <Grid item container xs={12} sx={styles.my3}>
                    <Grid item xs={7}>
                      <Typography
                        sx={
                          [
                            styles.poppinsWeight400Size18,
                            styles.greyColorAlpha78,
                          ] as SxProps
                        }
                      >
                        {hoursUndertaken}
                        {sem['Hours Completed']}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        sx={
                          [
                            styles.poppinsWeight400Size18,
                            styles.greyColorAlpha78,
                          ] as SxProps
                        }
                      >
                        {hoursPassed}
                        {sem['Hours Passed']}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography
                        sx={
                          [
                            styles.poppinsWeight400Size18,
                            styles.greyColorAlpha78,
                          ] as SxProps
                        }
                      >
                        {GPAHours}
                        {sem['GPA Hours']}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        sx={
                          [
                            styles.poppinsWeight400Size18,
                            styles.greyColorAlpha78,
                          ] as SxProps
                        }
                      >
                        {GRPoints}
                        {sem['GR Points'].toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography
                        sx={
                          [
                            styles.poppinsWeight400Size18,
                            styles.greyColorAlpha78,
                          ] as SxProps
                        }
                      >
                        {GPA}
                        {(GPASum / courseCount).toFixed(4)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item container spacing={0} xs={12}>
              <Box sx={styles.cumulativeContainer}>
                <Box sx={styles.my1 as SxProps}>
                  <Typography sx={styles.andadaProWeight700Size21 as SxProps}>
                    {accumulatedHeader}
                  </Typography>
                </Box>
                <Box sx={styles.my1 as SxProps}>
                  <Typography sx={styles.andadaProWeight700Size21 as SxProps}>
                    {hoursUndertaken}
                    {totalHrsCompleted}
                  </Typography>
                  <Typography sx={styles.andadaProWeight700Size21 as SxProps}>
                    {hoursPassed}
                    {totalHrsPassed}
                  </Typography>
                </Box>
                <Box sx={styles.my1 as SxProps}>
                  <Typography sx={styles.andadaProWeight700Size21 as SxProps}>
                    {GPAHours}
                    {totalGPAHrs}
                  </Typography>
                  <Typography sx={styles.andadaProWeight700Size21 as SxProps}>
                    {GRPoints}
                    {totalGRPTS.toFixed(2)}
                  </Typography>
                  <Typography sx={styles.andadaProWeight700Size21 as SxProps}>
                    {GPA}
                    {(totalGPA / totalCourse).toFixed(4)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        );
      }
      break;
    }

    default:
      Component = (
        <Box>
          <Typography
            sx={
              [styles.fieldLabelStyle, styles?.[`${fieldID}Label`]] as SxProps
            }
          >
            {label}
          </Typography>
          <Typography
            sx={
              [styles.fieldValueStyle, styles?.[`${fieldID}Value`]] as SxProps
            }
          >
            {value}
          </Typography>
        </Box>
      );

      break;
  }

  return Component;
};

type EducationTranscriptProps = {
  credentialsAsMap?: CredentialMap;
  isEmbeded?: boolean;
};

const EducationTranscript: React.FC<EducationTranscriptProps> = function ({
  credentialsAsMap,
  isEmbeded,
}) {
  const renderCandidate = () => (
    <Grid
      item
      container
      spacing={0}
      xs={12}
      sx={[styles.mt2, styles.mb1] as SxProps}
    >
      {candidateDisplayOrder?.map(fieldID => (
        <Grid item xs={12} key={fieldID}>
          {getComponent(fieldID, credentialsAsMap?.[fieldID], credentialsAsMap)}
        </Grid>
      ))}
    </Grid>
  );

  const renderTerms = () => (
    <Grid
      item
      container
      spacing={0}
      xs={12}
      sx={[styles.mt2, styles.mb1] as SxProps}
    >
      {termsDisplayOrder?.map(fieldID => (
        <Grid item xs={12} key={fieldID}>
          {getComponent(fieldID, credentialsAsMap?.[fieldID], credentialsAsMap)}
        </Grid>
      ))}
    </Grid>
  );

  const renderSigAndPrivacy = () => (
    <Grid
      item
      container
      spacing={0}
      xs={12}
      sx={[styles.mt2, styles.mb1] as SxProps}
    >
      {signatureDisplayOrder?.map(fieldID => (
        <Grid item xs={6} key={fieldID}>
          {getComponent(fieldID, credentialsAsMap?.[fieldID], credentialsAsMap)}
        </Grid>
      ))}
      {privacyDisplayOrder?.map(fieldID => (
        <Grid item xs={12} key={fieldID}>
          {getComponent(fieldID, credentialsAsMap?.[fieldID], credentialsAsMap)}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Container maxWidth="xs" disableGutters sx={styles.container}>
      {isEmbeded ? null : (
        <div>
          <Header />
          <RequestApprovedBanner />
        </div>
      )}

      <Grid
        container
        sx={isEmbeded ? styles.gridContainerEmbedded : styles.gridContainer}
        spacing={0}
      >
        <Grid
          item
          xs={12}
          container
          sx={
            isEmbeded
              ? styles.yellowgroundContainerEmbedded
              : styles.yellowgroundContainer
          }
        >
          {renderCandidate()}
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={
            isEmbeded
              ? styles.whitegroundContainerEmbedded
              : styles.whitegroundContainer
          }
        >
          {renderTerms()}
          {renderSigAndPrivacy()}
        </Grid>
      </Grid>
    </Container>
  );
};

EducationTranscript.defaultProps = {
  credentialsAsMap: {},
  isEmbeded: false,
};

export default EducationTranscript;
