import React, { useMemo } from "react";
import dateFormat from "dateformat";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { CredentialMap, credentialsAsMapToAttributes } from "../util";
import VellumLogo from "./vellum-logo.png";
import TreesBackground from "./trees-bg.png";
import VellumHologramBackground from "./vellum-hologram-bg.png";
import Header from "../../common/Header";
import RequestApprovedBanner from "../../common/RequestApprovedBanner";

type Attributes = {
  ["Authority Name"]: string;
  ["Authority Signature"]: string;
  ["Business Address"]: string;
  ["Business Name"]: string;
  ["Certificate Number"]: string;
  ["Date Of Issue"]: string;
  ["ESG Report URL"]: string;
  ["Vellum ESG Units"]: string;
  ["Verified By"]: string;
  ["Verified By Signature"]: string;
  ["Verified Period From"]: string;
  ["Verified Period To"]: string;
  ["Type"]: string;
};

function ESGCertificate({
  credentialsAsMap,
}: {
  credentialsAsMap: CredentialMap | undefined;
}) {
  const attributes = useMemo(
    () => credentialsAsMapToAttributes<Attributes>(credentialsAsMap),
    [credentialsAsMap]
  );

  const paddingVal = 20;

  return (
    <Container
      maxWidth="xs"
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
      disableGutters
    >
      <Header />
      <RequestApprovedBanner />

      <div style={{ padding: paddingVal }}>
        <img
          src={VellumLogo}
          alt="Vellum Logo"
          style={{ marginBottom: 30 }}
          width={150}
        />

        <Typography variant="body1" color="grey">
          {attributes["Certificate Number"]}
        </Typography>
        <Typography variant="h4" mb={5}>
          ESG Certificate
        </Typography>
        <Typography variant="body2" color="grey">
          This is to certify that:
        </Typography>
      </div>

      <div
        style={{
          backgroundImage: `url(${VellumHologramBackground})`,
          backgroundPosition: "right 10px top 10px",
          backgroundRepeat: "no-repeat",
          backgroundSize: 200,
        }}
      >
        <div style={{ padding: paddingVal }}>
          <Typography variant="h6">{attributes["Business Name"]}</Typography>
          <Typography variant="body1" mb={3}>
            {attributes["Business Address"]}
          </Typography>

          <Typography variant="body2" color="grey" mb={1}>
            owns
          </Typography>
          <Typography variant="h5">{attributes["Vellum ESG Units"]}</Typography>
          <Typography variant="body1" mb={3}>
            Vellum ESG Units
          </Typography>

          <Typography variant="body2" color="grey">
            For and on behalf of VELLUM
          </Typography>
          <img
            src={attributes["Authority Signature"]}
            alt="Authority Signature"
            width={150}
          />
          <Typography variant="body1" mb={3}>
            {attributes["Authority Name"]}
          </Typography>

          <Typography variant="body2" color="grey">
            Date of Issue
          </Typography>
          <Typography variant="body1" mb={3}>
            {dateFormat(attributes["Date Of Issue"], "dd mmmm yyyy")}
          </Typography>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${TreesBackground})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div style={{ padding: paddingVal }}>
          <Typography variant="body2" color="grey">
            Verified By
          </Typography>
          <img
            src={attributes["Verified By Signature"]}
            alt="Verified By Signature"
            width={150}
          />
          <Typography variant="body1" mb={3}>
            {attributes["Verified By"]}
          </Typography>

          <Typography variant="body2" color="grey">
            Verified Period
          </Typography>
          <Typography variant="body1" mb={3}>
            {dateFormat(attributes["Verified Period From"], "dd mmmm yyyy")} -{" "}
            {dateFormat(attributes["Verified Period To"], "dd mmmm yyyy")}
          </Typography>

          <Typography variant="body2" color="grey" mb={5}>
            This certificate was issued electronically and remains the property
            of Vellum Australia Pty Ltd (ACN: 605 190 369).
          </Typography>

          <div
            style={{ border: "3px solid white", padding: 10, marginBottom: 20 }}
          >
            <Typography variant="h6" color="white">
              Vellum
            </Typography>
            <hr />

            <Typography variant="body2" color="white">
              Level 36, 1 Macquarie Place, Sydney
            </Typography>
            <Typography variant="body2" mb={3} color="white">
              NSW 2000
            </Typography>
            <Typography variant="body2" color="white">
              1300 838 338
            </Typography>
            <Typography variant="body2" color="white">
              esg@vellum.com.au
            </Typography>
            <Typography variant="body2" color="white">
              www.vellum.com.au
            </Typography>
          </div>

          <div style={{ textAlign: "center" }}>
            <Typography variant="body2" color="white">
              {dateFormat(new Date(), "dd mmm yyyy HH:MM:ss")}
            </Typography>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ESGCertificate;
